import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./carousel-styles.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

const host = "https://tonyweissinger-images.s3.amazonaws.com/"

const tonyPhotos = [
  { name: "photo A", url: "newer/TCurry-2680_8x10.jpg" },
  { name: "photo B", url: "newer/TCurry-2484_8x10.jpg" },
  { name: "photo C", url: "newer/TWeissinger-2662_online.jpg" },
  { name: "photo D", url: "newer/TWeissinger-2203_8x10.jpg" },
  { name: "photo E", url: "2017_smaller_Weissinger_041.png" },
  { name: "photo F", url: "2017_smaller_Weissinger_067.jpg" },
  { name: "photo G", url: "2017_smaller_Weissinger_073.png" },
  { name: "photo H", url: "2017_smaller_Weissinger_091.png" },
  { name: "photo I", url: "2017_smaller_Weissinger_093.jpg" },
  { name: "photo J", url: "2017_smaller_Weissinger_108.png" },
]

const midsummerPhotos = [
  { name: "photo A", url: "_28V7537.jpg" },
  { name: "photo B", url: "_28V7543.jpg" },
  { name: "photo C", url: "_28V8153.jpg" },
]

const christmasCarolPhotos = [
  { name: "photo A", url: "acc-2014-and-sam.jpg" },
  { name: "photo B", url: "acc-2014-and-tiny-tim.jpg" },
  { name: "photo C", url: "acc-2014-backstage.jpg" },
  { name: "photo D", url: "acc-2014-cast.jpg" },
  { name: "photo D", url: "acc-2014-cratchits.jpg" },
]

const Gallery = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    className: "slides",
  }

  return (
    <Layout>
      <SEO title="Gallery" />
      <div style={{ padding: 24 }}>
        <Slider {...settings}>
          {tonyPhotos.map(photo => (
            <div>
              <img width="100%" src={host + photo.url} alt={photo.name} />
            </div>
          ))}
        </Slider>
        A Midsummer Night's Dream
        <Slider {...settings}>
          {midsummerPhotos.map(photo => (
            <div>
              <img width="100%" src={host + photo.url} alt={photo.name} />
            </div>
          ))}
        </Slider>
        A Christmas Carol
        <Slider {...settings}>
          {christmasCarolPhotos.map(photo => (
            <div>
              <img width="100%" src={host + photo.url} alt={photo.name} />
            </div>
          ))}
        </Slider>
      </div>
      <Link to="/">Go home</Link>
    </Layout>
  )
}

export default Gallery
